/* eslint-disable import/no-anonymous-default-export */
import actions from "./action";

const initState = {
  fileData: {},
  loading: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.REQUEST_FILE_UPLOAD: {
      return {
        ...state,
        fileData: {},
        loading: true,
      };
    }
    case actions.FILE_UPLOAD_SUCCESS: {
      return {
        ...state,
        fileData: action.payload,
        loading: false,
      };
    }

    case actions.FILE_UPLOAD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_FILE: {
      return {
        ...state,
        fileData: {},
        loading: true,
      };
    }
    case actions.GET_FILE_SUCCESS: {
      return {
        ...state,
        fileData: action.payload,
        loading: false,
      };
    }
    case actions.GET_FILE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.CLEAR_FILE_DATA: {
      return {
        ...state,
        fileData: null,
      };
    }

    default:
      return state;
  }
};
