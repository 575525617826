import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, Radio, Avatar, Dropdown, Checkbox } from 'antd'
import { CategoryType } from 'types/globalTypes'
import { useGetEventCategories } from 'api/EventCreation/EventCreation'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import '../styles/general-details.scss'

interface CategoryInputTypes {
  category: CategoryType
  setCategory: React.Dispatch<React.SetStateAction<CategoryType>>
}

const CategoryInput: React.FC<CategoryInputTypes> = ({
  category,
  setCategory,
}) => {
  const { Option } = Select

  const {
    data: eventCategories,
    isLoading: eventCategoryLoading,
    refetch: eventCategoryRefetch,
  } = useGetEventCategories()

  const onChange = (e: CheckboxChangeEvent, selectedItem: CategoryType) => {
    e.target.checked && setCategory(selectedItem)
  }

  const handleItemClick = (selectedItem: CategoryType) => {
    setCategory(selectedItem)
  }

  const items =
    eventCategories?.length > 0 &&
    eventCategories.map((item: CategoryType) => {
      return {
        key: item._id,
        label: (
          <div
            className="flex align-center justify-between"
            onClick={() => handleItemClick(item)}
          >
            <div className="flex align-center gap-10px">
              <Avatar src={item.icon} size={34} />
              <span>{item.name}</span>
            </div>
            <Checkbox
              onChange={e => onChange(e, item)}
              name={item.name}
              checked={category?._id === item._id}
              className="custom-category-checkbox"
            ></Checkbox>
          </div>
        ),
      }
    })

  useEffect(() => {
    eventCategoryRefetch()
  }, [])
  return (
    <>
      <Row gutter={10}>
        <Col>
          <label className="input-label required-input">Category</label>
        </Col>

        <Dropdown
          menu={{
            items,
          }}
          placement="bottomLeft"
          trigger={['click']}
          className="category-input-block"
          overlayStyle={{
            minWidth: '350px',
            height: '400px',
            overflowY: 'auto',
            background: '#fff',
            borderRadius: '8px',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          }}
        >
          <Col style={{ height: '50px' }}>
            <div className="w-full border-1px radius-10 flex align-center px-12px pointer h-full">
              {category ? (
                <div className="flex align-center gap-5px">
                  <Avatar src={category.icon} size={18} />
                  <p>{category.name}</p>
                </div>
              ) : (
                <p className="text-gray">Select category</p>
              )}
            </div>
          </Col>
        </Dropdown>
      </Row>
    </>
  )
}

export default CategoryInput
