import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Form,
  Modal,
  Input,
  Button,
  Select,
  DatePicker,
  Radio,
  TimePicker,
} from 'antd'
import '../styles/modal-styles.scss'
import closeIcon from 'assets/Images/close.svg'
import DateandTimeInputs from '../../../GeneralDetails/InputComps/DateandTimeInputs'
import TicketColors from '../TicketColors'
import { AdmissionTicketPayloadTypes, TicketsResp } from 'types/eventStep4'
import moment, { Moment } from 'moment'
import {
  convertMinutesToLocalTime,
  convertTimeToUtcMinutes,
} from 'helper/utility'
import {
  useCreateAdditionalTicket,
  useCreateAdmissionTicket,
} from 'api/EventCreation/CreationStep4'
import { creationEventStore } from 'state/eventCreationStore'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { infoMessage } from 'api/global'
import { SpecialSchedule } from 'types/globalTypes'

interface AdmissionTicketModalProps {
  modalOpen: boolean
  modalClose: React.Dispatch<React.SetStateAction<boolean>>
  type: 'admission' | 'additional' | 'dropin' | 'discount'
  data?: TicketsResp
}

const AdmissionTicket: React.FC<AdmissionTicketModalProps> = ({
  modalOpen,
  modalClose,
  type,
  data,
}) => {
  const [form] = Form.useForm()
  const { _id, admissionTickets, datesOfEvent } = creationEventStore(
    store => store.eventData,
  )
  const setEventData = creationEventStore(store => store.setEventData)
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [specialScheduleData, setSpecialScheduleData] = useState([])
  const [ticketType, setTicketType] = useState<string>(
    data ? data.ticketType : undefined,
  )
  const [eventTime, setEventTime] = useState({
    startTime: undefined,
    endTime: undefined,
  })
  const [ticketColor, setTicketColor] = useState<string>(
    data ? data.color : undefined,
  )
  const [admissionTicketId, setAdmissionTicketId] = useState<
    string | undefined
  >(type === 'additional' ? data?.allowedTicketsIds[0] : undefined)

  const { mutate: admissionTicketMutate, isLoading: admissionTicketLoading } =
    useCreateAdmissionTicket()
  const { mutate: additionalTicketMutate, isLoading: additionalTicketLoading } =
    useCreateAdditionalTicket()

  const formSchema = {
    name: data ? data.name : undefined,
    ticketType: data ? data.ticketType : undefined,
    expiry: data?.expiry ? moment(data.expiry) : undefined,
    ['expiry-time']: data?.expiry ? moment(data.expiry) : undefined,
    numberOfDays: data ? data?.numberOfDays : undefined,
    description: data ? data.description : undefined,
    price: data ? data.price : undefined,
    allowedTicketsIds: data ? data.allowedTicketsIds : [],
    guestAllowedToVisit: data ? data?.guestAllowedToVisit : 'all',
    minAge: data ? data?.minAge : undefined,
    maxAge: data ? data?.maxAge : undefined,
    limitPerFamily: data ? data?.limitPerFamily : undefined,
    totalLimit: data ? data?.totalLimit : undefined,
  }
  const handleCancel = () => {
    modalClose(false)
  }

  const handleTicketType = (event: string) => {
    setTicketType(event)
  }

  const handleAdmissionTicketChange = (value: string) => {
    setAdmissionTicketId(value)
  }

  const disableOutOfRangeDates = (current: Moment) => {
    const firstDate = moment(datesOfEvent[0]).startOf('day')
    const lastDate = moment(datesOfEvent[datesOfEvent.length - 1]).endOf('day')
    return current < firstDate || current > lastDate
  }

  const onFinish = (values: AdmissionTicketPayloadTypes) => {
    if (!values.name) {
      return infoMessage('Name is required')
    }
    if (type === 'additional' && !values.allowedTicketsIds) {
      return infoMessage('Allowed ticket is required')
    }
    if (!values.ticketType) {
      return infoMessage('Ticket type is required')
    }
    if (selectedDates.length === 0) {
      return infoMessage('Ticket days and time is required')
    }
    if (!values.price) {
      return infoMessage('Ticket price is required')
    }
    if (!ticketColor) {
      return infoMessage('Ticket color is required')
    }
    if (!values.guestAllowedToVisit) {
      return infoMessage('Guest allowed to visit is required')
    }
    if (values.numberOfDays && values.numberOfDays > selectedDates.length) {
      return infoMessage(
        'Max number of days must be equal to less than ticket days',
      )
    }
    if (!values.numberOfDays && ticketType !== 'preselected') {
      return infoMessage('Max number of days is required')
    }

    const payload: AdmissionTicketPayloadTypes = {
      ...values,
      color: ticketColor,
      days: selectedDates.map((item: string) =>
        moment(
          new Date(moment(item).format('YYYY-MM-DDTHH:mm:ss[Z]')).setHours(
            parseInt(moment(eventTime.startTime).utc().format('HH:mm'), 10),
            0,
            0,
            0,
          ),
        ).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      ),
      timings: {
        startTime: convertTimeToUtcMinutes(eventTime.startTime),
        endTime: convertTimeToUtcMinutes(eventTime.endTime),
        specialSchedules: specialScheduleData.map((data: SpecialSchedule) => ({
          ...data,
          day: moment(data.day, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        })),
      },
      expiry:
        values.expiry && values['expiry-time']
          ? moment
              .utc(
                `${moment(values.expiry).format('YYYY-MM-DD')} ${moment
                  .utc(values['expiry-time'])
                  .format('HH:mm')}`,
              )
              .format()
          : undefined,
      allowedTicketsIds: Array.isArray(values.allowedTicketsIds)
        ? values.allowedTicketsIds
        : [values.allowedTicketsIds],
    }
    if (type === 'admission') {
      admissionTicketMutate(
        { payload: payload, eventId: _id, ticketId: data?._id },
        {
          onSuccess: result => {
            setEventData(result)
            modalClose(false)
          },
        },
      )
    } else if (type === 'additional') {
      additionalTicketMutate(
        { payload: payload, eventId: _id, ticketId: data?._id },
        {
          onSuccess: result => {
            setEventData(result)
            modalClose(false)
          },
        },
      )
    }
  }

  useEffect(() => {
    if (type !== 'dropin' && data) {
      setSelectedDates(
        (data as TicketsResp).days.map((date: string) =>
          moment(date).format('YYYY-MM-DD'),
        ),
      )
      setEventTime({
        startTime: moment(
          convertMinutesToLocalTime(
            (data as TicketsResp).timings.startTime,
            'HH:mm',
          ),
          'HH:mm',
        ),
        endTime: moment(
          convertMinutesToLocalTime(
            (data as TicketsResp).timings.endTime,
            'HH:mm',
          ),
          'HH:mm',
        ),
      })
    }
  }, [data])

  return (
    <div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        className="admission-ticket-modal"
        footer={false}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">
            {type === 'admission' ? 'Admission ticket' : 'Additional Ticket'}
          </h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="h-30rem mt-20px overflow-auto-y overflow-hidden-x scroller">
          <Form
            form={form}
            layout="horizontal"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">
                  Ticket title
                </label>
              </Col>
              <Col>
                <Form.Item className="form-control" name="name">
                  <Input
                    placeholder="Ticket title"
                    className="form-control-field"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">
                  Ticket type
                </label>
              </Col>
              <Col>
                <Form.Item className="pointer selector-input" name="ticketType">
                  <Select
                    className="select-input-field type-select-field"
                    placeholder="Select"
                    optionLabelProp="value"
                    onChange={handleTicketType}
                    options={[
                      {
                        value: 'preselected',
                        label: (
                          <>
                            <h1 className="text-16 w-500">Preselected</h1>{' '}
                            <p className="grey-txt">
                              Define the exact days of tickets
                            </p>
                          </>
                        ),
                      },
                      {
                        value: 'open',
                        label: (
                          <>
                            <h1 className="text-16 w-500">Open</h1>{' '}
                            <p className="grey-txt">
                              Any number of days within period
                            </p>
                          </>
                        ),
                      },
                      {
                        value: 'schedule',
                        label: (
                          <>
                            <h1 className="text-16 w-500">Schedule</h1>{' '}
                            <p className="grey-txt">Day of week can choose</p>
                          </>
                        ),
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>

            {type === 'additional' && (
              <Row gutter={10}>
                <Col>
                  <label className="input-label required-input">
                    Allowed tickets
                  </label>
                </Col>
                <Col>
                  <Form.Item
                    className="pointer selector-input"
                    name="allowedTicketsIds"
                  >
                    <Select
                      className="select-input-field type-select-field"
                      placeholder="Select"
                      optionLabelProp="label"
                      options={admissionTickets.map((ticket: TicketsResp) => {
                        return {
                          value: ticket._id,
                          label: ticket.name,
                        }
                      })}
                      onChange={handleAdmissionTicketChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row>
              <Col span={24}>
                <label htmlFor="" className="input-label">
                  Expiration date
                </label>
                <Form.Item name="expiry">
                  <DatePicker
                    className="w-100 form-control-field"
                    placeholder="Expiration date"
                    disabledDate={disableOutOfRangeDates}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label htmlFor="" className="input-label">
                  Expiration Time
                </label>
                <Form.Item name="expiry-time">
                  <TimePicker
                    format={'hh:mm a'}
                    className="w-100 form-control-field"
                    placeholder="Expiration time"
                  />
                </Form.Item>
              </Col>
            </Row>

            <DateandTimeInputs
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              specialScheduleData={specialScheduleData}
              setSpecialScheduleData={setSpecialScheduleData}
              eventTime={eventTime}
              setEventTime={setEventTime}
              type={type}
              allowedTicketId={admissionTicketId}
            />

            {ticketType !== 'preselected' && (
              <Row className="mt-20px">
                <Col className="d-flex align-items-center">
                  <div
                    className="relative"
                    style={{ width: '100%', marginTop: '-14px' }}
                  >
                    <span className="custom-field-label required-input">
                      Max Registration days
                    </span>
                  </div>
                  <Form.Item name="numberOfDays" style={{ width: '20%' }}>
                    <Input
                      type="number"
                      min={0}
                      placeholder="0"
                      className="text-end br-12"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row className="mt-10px">
              <Col span={24}>
                <label htmlFor="" className="custom-field-label">
                  Description
                </label>
                <Form.Item name="description">
                  <Input.TextArea
                    className="note-field"
                    placeholder="Terms of usage of the ticket"
                    autoSize={{ minRows: 3, maxRows: 10 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex align-items-center">
                <div
                  className="relative"
                  style={{ width: '100%', marginTop: '-14px' }}
                >
                  <span className="custom-field-label required-input">
                    Price
                  </span>
                </div>
                <Form.Item name="price" style={{ width: '20%' }}>
                  <Input
                    type="number"
                    placeholder="0"
                    className="text-end br-12"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label className="input-label required-input">
                  Select colors
                </label>
                <TicketColors
                  ticketColor={ticketColor}
                  setTicketColor={setTicketColor}
                />
              </Col>
            </Row>

            <Row className="mt-20px">
              <Col span={24}>
                <label className="input-label required-input">
                  Guests allowed to visit
                </label>
                <Form.Item
                  className="form-control mb-0"
                  name="guestAllowedToVisit"
                >
                  <Radio.Group>
                    <Radio value={'all'} className="mr-40 radio">
                      All
                    </Radio>
                    <Radio value={'boys'} className="mr-40 radio">
                      Boys
                    </Radio>
                    <Radio value={'girls'} className="mr-40 radio">
                      Girls
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <div className="w-80p admission-ticket-inputs mt-20px">
              <Row>
                <Col>
                  <h4 className="b1 mb-15">Guests limitation</h4>
                </Col>
                <Col className="d-flex align-items-center">
                  <div style={{ width: '100%', marginTop: '-14px' }}>
                    <span>Age restrictions (min)</span>
                  </div>
                  <Form.Item name="minAge" style={{ width: '30%' }}>
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end br-5"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col className="d-flex align-items-center">
                  <div style={{ width: '100%', marginTop: '-14px' }}>
                    <span>Age restrictions (max)</span>
                  </div>
                  <Form.Item name="maxAge" style={{ width: '30%' }}>
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end br-5"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col className="d-flex align-items-center">
                  <div style={{ width: '100%', marginTop: '-14px' }}>
                    <span>Limit per Family</span>
                  </div>
                  <Form.Item name="limitPerFamily" style={{ width: '30%' }}>
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end br-5"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col className="d-flex align-items-center">
                  <div style={{ width: '100%', marginTop: '-14px' }}>
                    <span>Total tickets limit</span>
                  </div>
                  <Form.Item name="totalLimit" style={{ width: '30%' }}>
                    <Input
                      type="totalTicket"
                      placeholder="0"
                      className="text-end br-5 radius-12"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Row justify="end" className="mt-20px">
              <Button
                className="stepper-button"
                disabled={admissionTicketLoading || additionalTicketLoading}
                htmlType="submit"
              >
                {admissionTicketLoading || additionalTicketLoading ? (
                  <ButtonSpinner size={20} color="#000" />
                ) : (
                  'Save'
                )}
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default AdmissionTicket
