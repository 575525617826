import React, { useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import { EventResp } from 'types/events'
import { SpecialSchedule } from 'types/globalTypes'
import moment from 'moment'
import { creationEventStore } from 'state/eventCreationStore'

interface StaffAndSalaryProps {
  data: EventResp
  numberOfStaff: number
  setNumberOfStaff: React.Dispatch<React.SetStateAction<number>>
  estimatedSalary: {
    min: number
    max: number
  }
  setEstimatedSalary: React.Dispatch<
    React.SetStateAction<{
      min: number
      max: number
    }>
  >
}

const StaffAndSalary: React.FC<StaffAndSalaryProps> = ({
  data,
  numberOfStaff,
  setNumberOfStaff,
  estimatedSalary,
  setEstimatedSalary,
}) => {
  const step3Payload = creationEventStore(store => store.step3Payload)
  const setStep3Payload = creationEventStore(store => store.setStep3Payload)

  const calculateEstimatedHour = (): number => {
    let estimatedHours = 0
    data?.datesOfEvent.map((date: string) => {
      let hours = 0

      const specialSchedule = data.timings.specialSchedules.find(
        (schedule: SpecialSchedule) =>
          moment(schedule.day).format('YYYY-MM-DD') ===
          moment(date).format('YYYY-MM-DD'),
      )

      if (specialSchedule) {
        const minutes = specialSchedule.endTime - specialSchedule.startTime
        hours = minutes / 60
      } else {
        const minutes = data.timings.endTime - data.timings.startTime
        hours = minutes / 60
      }

      estimatedHours += hours
    })
    // return estimatedHours
    return Math.abs(estimatedHours)
  }

  useEffect(() => {
    if (data?.maxGuestsAllowed) {
      setStep3Payload({ numOfStaff: data.maxGuestsAllowed / 10 })
      setNumberOfStaff(data.maxGuestsAllowed / 10)
    } else {
      setStep3Payload({ numOfStaff: 1 })
      setNumberOfStaff(1)
    }

    setEstimatedSalary({
      min: calculateEstimatedHour() * 20,
      max: calculateEstimatedHour() * 30,
    })
  }, [data])

  useEffect(() => {
    setStep3Payload({
      estimatedUnitStaffSalary: {
        min: calculateEstimatedHour() * 20,
        max: calculateEstimatedHour() * 30,
      },
      totalStaffSalary: {
        min: calculateEstimatedHour() * 20 * step3Payload.numOfStaff,
        max: calculateEstimatedHour() * 30 * step3Payload.numOfStaff,
      },
    })
  }, [step3Payload.numOfStaff])

  return (
    <>
      <div className="d-flex align-items-center justify-between">
        <div style={{ flex: 1 }}>
          <span className="text-14 w-600">Number of staff</span>
        </div>
        <div style={{ width: '90px' }}>
          <Input
            type="number"
            placeholder="0"
            value={numberOfStaff}
            onChange={e => setNumberOfStaff(Number(e.target.value))}
            className="text-end br-12 text-center"
          />
        </div>
      </div>

      <div className="mt-25px flex align-center justify-between">
        <h4 className="text-14 w-500">Estimated salary for each person</h4>
        <h4>
          <strong className="text-16 w-600">
            ${estimatedSalary.min.toFixed(1)} ~ $
            {estimatedSalary.max.toFixed(1)}
          </strong>
        </h4>
      </div>
    </>
  )
}

export default StaffAndSalary
