import React, { useState, useEffect } from 'react'
import { Row, Col, Form, TimePicker } from 'antd'
import moment, { Moment } from 'moment'
import Modal from 'antd/lib/modal/Modal'
import Calendar from 'react-calendar'
import '../styles/general-details.scss'
import { DeleteOutlined } from '@ant-design/icons'
import { PlusCircle } from '@phosphor-icons/react'
import { creationEventStore } from 'state/eventCreationStore'
import { convertMinutesToLocalTime } from 'helper/utility'
import { SpecialSchedule } from 'types/globalTypes'
import { TicketsResp } from 'types/eventStep4'

const SelectMultiDaysCalenderModel = ({
  setSpecialScheduleVisible,
  setIsSelectMultiDaysCalenderVisible,
  selectedDates,
  setSelectedDates,
  setEventTime,
  specialScheduleData,
  setSpecialScheduleData,
  step1,
  type,
  allowedTicketId,
}: any) => {
  const eventData = creationEventStore(store => store.eventData)
  const [form] = Form.useForm()
  const [eventDates, setEventDates] = useState<Date[]>([])
  const [defaultTime, setDefaultTime] = useState({
    startTime: eventData?.timings
      ? moment(
          convertMinutesToLocalTime(eventData?.timings.startTime, 'HH:mm'),
          'HH:mm',
        )
      : moment('9:00', 'HH:mm'),
    endTime: eventData?.timings
      ? moment(
          convertMinutesToLocalTime(eventData?.timings.endTime, 'HH:mm'),
          'HH: mm',
        )
      : moment('15:00', 'HH:mm'),
  })

  const handleStartTimeChange = (time: Moment | null, timeString: string) => {
    setDefaultTime({ ...defaultTime, startTime: moment(time) })
  }
  const handleEndTimeChange = (time: Moment | null, timeString: string) => {
    setDefaultTime({ ...defaultTime, endTime: moment(time) })
  }

  const handleTileClick = (date: any) => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    if (!selectedDates?.includes(formattedDate)) {
      if (selectedDates) {
        setSelectedDates([...selectedDates, formattedDate])
      } else {
        setSelectedDates([formattedDate])
      }
    } else {
      setSelectedDates(
        selectedDates.filter(
          (selectedDate: any) => selectedDate !== formattedDate,
        ),
      )
    }
  }

  const removeSpecialscheduleInput = (index: number) => {
    const current: any = specialScheduleData[index]
    setSpecialScheduleData([
      ...specialScheduleData.filter((data: any) => data.day !== current.day),
    ])
  }

  const onAddPress = (values: any) => {
    setIsSelectMultiDaysCalenderVisible(false)
  }

  useEffect(() => {
    setEventTime(defaultTime)
  }, [defaultTime])

  useEffect(() => {
    if (type === 'admission') {
      const locallyConvertedDate = []
      eventData?.datesOfEvent.map((date: string) => {
        const utcDate = new Date(date)
        utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset())
        locallyConvertedDate.push(utcDate)
      })
      setEventDates(locallyConvertedDate)
    } else if (type === 'additional') {
      const locallyConvertedDate = []
      const selectedAdmissionTicket = eventData.admissionTickets.find(
        (ticket: TicketsResp) => ticket._id === allowedTicketId,
      )
      selectedAdmissionTicket.days.map(date => {
        const utcDate = new Date(date)
        utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset())
        locallyConvertedDate.push(utcDate)
      })
      setEventDates(locallyConvertedDate)
    }
  }, [eventData])

  // console.log(specialScheduleData, 'this is the special schedule...')

  return (
    <div>
      <Modal
        open={true}
        className="map-modal add-more-fees-modal"
        footer={null}
        centered={true}
        width={450}
        onCancel={() => {
          setIsSelectMultiDaysCalenderVisible(false)
        }}
      >
        <h1 className="text-center text-24px w1"> Select Event Days </h1>
        <Form
          className="-mt-20px"
          form={form}
          layout="horizontal"
          onFinish={onAddPress}
        >
          <div className="h-30rem overflow-auto-y scroller pb-20px">
            <div className="event-days-calendar">
              <Row style={{ marginTop: 50 }}>
                <Col>
                  <Form.Item name="date">
                    {step1 ? (
                      <Calendar
                        minDate={new Date()}
                        onClickDay={handleTileClick}
                        tileClassName={({ date, view }) => {
                          if (
                            selectedDates?.find(
                              (x: any) =>
                                x === moment(date).format('YYYY-MM-DD'),
                            )
                          ) {
                            return 'selected'
                          }
                        }}
                      />
                    ) : (
                      <Calendar
                        minDate={
                          eventDates &&
                          moment(eventDates[0], 'YYYY-MM-DD').toDate()
                        }
                        onClickDay={handleTileClick}
                        tileClassName={({ date, view }) => {
                          if (
                            selectedDates?.find(
                              (x: any) =>
                                x === moment(date).format('YYYY-MM-DD'),
                            )
                          ) {
                            return 'selected'
                          }
                        }}
                        maxDate={
                          eventDates &&
                          moment(
                            eventDates[eventDates.length - 1],
                            'YYYY-MM-DD',
                          ).toDate()
                        }
                        tileDisabled={({ date }) =>
                          !eventDates.some(
                            eventDate =>
                              eventDate.getDate() === date.getDate() &&
                              eventDate.getMonth() === date.getMonth(),
                          )
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div>
              <h2 className="text-14 w-600">Weekly default schedule</h2>
              <div className="flex align-center gap-16px mt-15px w-70p">
                <TimePicker
                  className="w-100 form-control-field"
                  placeholder="Start Time"
                  format="h:mm a"
                  value={defaultTime.startTime}
                  showNow={false}
                  clearIcon={false}
                  onChange={handleStartTimeChange}
                />
                <div>-</div>
                <TimePicker
                  className="w-100 form-control-field"
                  placeholder="End Time"
                  format="h:mm a"
                  value={defaultTime.endTime}
                  showNow={false}
                  clearIcon={false}
                  onChange={handleEndTimeChange}
                />
              </div>
            </div>

            <div className="flex align-center justify-between gap-16px mt-20px">
              <h2 className="text-14 w-600">Add special schedule</h2>
              <PlusCircle
                className="pointer"
                size={20}
                color="#000"
                onClick={() => setSpecialScheduleVisible(true)}
              />
            </div>

            {specialScheduleData.length > 0 && (
              <div className="mt-20px">
                <div>
                  {specialScheduleData.map(
                    (specialSchedule: SpecialSchedule, index: number) => {
                      return (
                        <div className="mt-10px" key={index}>
                          <h4 className="text-14 w-600">
                            {/* {specialSchedule.day} */}
                            {moment(specialSchedule.day).format('DD-MM-YYYY')}
                          </h4>
                          <div>
                            <div className="flex justify-between align-center w-full">
                              <div className="flex align-center gap-16px mt-15px">
                                <TimePicker
                                  className="w-100 form-control-field"
                                  placeholder="Start Time"
                                  format="h:mm a"
                                  showNow={false}
                                  clearIcon={false}
                                  value={moment(
                                    convertMinutesToLocalTime(
                                      specialSchedule.startTime,
                                    ),
                                    'hh:mm A',
                                  )}
                                  onChange={handleStartTimeChange}
                                  disabled
                                />
                                <div>-</div>
                                <TimePicker
                                  className="w-100 form-control-field"
                                  placeholder="End Time"
                                  format="h:mm a"
                                  showNow={false}
                                  clearIcon={false}
                                  value={moment(
                                    convertMinutesToLocalTime(
                                      specialSchedule.endTime,
                                    ),
                                    'hh:mm A',
                                  )}
                                  onChange={handleEndTimeChange}
                                  disabled
                                />
                              </div>

                              <DeleteOutlined
                                onClick={() =>
                                  removeSpecialscheduleInput(index)
                                }
                                style={{
                                  color: '#FF6961',
                                  paddingLeft: '5px',
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    },
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-center align-center gap-16px mt-15px">
            <button
              className="btn-gray br-8"
              onClick={() => setIsSelectMultiDaysCalenderVisible(false)}
            >
              Cancel
            </button>
            <button className="prim-btn cursor-pointer" type="submit">
              Save
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
export default SelectMultiDaysCalenderModel
