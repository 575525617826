const actions = {
  FILE_UPLOAD: "FILE_UPLOAD",
  REQUEST_FILE_UPLOAD: "REQUEST_FILE_UPLOAD",
  FILE_UPLOAD_SUCCESS: "FILE_UPLOAD_SUCCESS",
  FILE_UPLOAD_FAILURE: "FILE_UPLOAD_FAILURE",
  GET_FILE: "GET_FILE",
  GET_FILE_SUCCESS: "GET_FILE_SUCCESS",
  GET_FILE_FAILURE: "GET_FILE_FAILURE",
  CLEAR_FILE_DATA: "CLEAR_FILE_DATA",
  FILE_UPLOAD_ENTRY_SUCCESS: "FILE_UPLOAD_ENTRY_SUCCESS",
  FILE_UPLOAD_ENTRY_FAILURE: "FILE_UPLOAD_ENTRY_FAILURE",

  fileUploadRequest: (payload, newFile) => ({
    type: actions.REQUEST_FILE_UPLOAD,
    payload,
    newFile,
  }),

  fileUploadEntrySuccess: (payload, id) => ({
    type: actions.FILE_UPLOAD_ENTRY_SUCCESS,
    payload,
    id,
  }),

  fileUploadEntryFailure: () => ({
    type: actions.FILE_UPLOAD_ENTRY_FAILURE,
  }),

  fileUploadSuccess: (payload) => ({
    type: actions.FILE_UPLOAD_SUCCESS,
    payload,
  }),

  fileUploadFailure: () => ({
    type: actions.FILE_UPLOAD_FAILURE,
  }),

  getFile: (fileId) => ({
    type: actions.GET_FILE,
    fileId,
  }),

  getFileSuccess: (payload) => ({
    type: actions.GET_FILE_SUCCESS,
    payload,
  }),

  getFileFailure: () => ({
    type: actions.GET_FILE_FAILURE,
  }),

  clearFileData: () => ({
    type: actions.CLEAR_FILE_DATA,
  }),
};

export default actions;
