import { useEffect, useState } from 'react'
import AppLayout from '../../components/layout/layout'
import { Row, Col } from 'antd'
import EventHeader from './Header/index'
import { myStore } from '../../state/eventStore'
import './styles.scss'
import { useQuery } from '@tanstack/react-query'
import { getEvents } from '../../api/Events'
import EventView from '../../components/Events/Views'
import Loader from '../../helper/Loader'

const Events = () => {
  const [parms, setParms] = useState({
    page: 1,
    orderBy: 'desc',
  })

  const { isLoading, data, refetch } = useQuery(
    ['getEvents', parms],
    () => getEvents(parms),
    {
      enabled: false,
    },
  )
  useEffect(() => {
    refetch()
  }, [parms])
  const view = myStore(store => store.view)
  return (
    <AppLayout>
      <div className="container-event">
        <div className="page-content events-page-content">
          <EventHeader setParm={setParms} parms={parms} />
          <Row gutter={[0, 20]}>
            <Col>
              <div>
                {isLoading ? (
                  <div className="h-full">
                    <Loader />
                  </div>
                ) : (
                  <EventView
                    data={data}
                    parms={parms}
                    setParms={setParms}
                    view={view}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </AppLayout>
  )
}

export default Events
