import React, { useState } from 'react'
import { Col, Form, Input, Modal, Row } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import QuillInput from './QuillInput'
import TemplateDetails from './TemplateDetails'
import PreviewTemplate from './PreviewTemplate'

interface ConfirmationModalProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  openModal,
  closeModal,
}) => {
  const [openTemplateDetail, setOpenTemplateDetail] = useState<boolean>(false)
  const [preview, setPreview] = useState<boolean>(false)
  const [text, setText] = useState<string>('')
  const [form] = Form.useForm()

  const formSchema = {}

  const handleCancel = () => {
    closeModal(false)
  }

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="confirmation-email"
        footer={false}
        width={700}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Confirmation email template</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px h-26rem scroller overflow-auto-y">
          <Form form={form} layout="vertical" initialValues={formSchema}>
            <QuillInput text={text} setText={setText} />
          </Form>
        </div>
        <Row className="mt-20px justify-end gap-16px">
          <button className="secondary-confirmation" onClick={handleCancel}>
            Back
          </button>
          <button
            className="secondary-confirmation"
            onClick={() => setPreview(true)}
          >
            Preview
          </button>
          <button
            className="confirm-btn"
            onClick={() => setOpenTemplateDetail(true)}
          >
            Confirm
          </button>
        </Row>
      </Modal>

      {preview && (
        <PreviewTemplate
          openModal={preview}
          closeModal={setPreview}
          text={text}
        />
      )}

      {openTemplateDetail && (
        <TemplateDetails
          openModal={openTemplateDetail}
          closeModal={setOpenTemplateDetail}
          body={text}
          closeParent={handleCancel}
        />
      )}
    </div>
  )
}

export default ConfirmationModal
