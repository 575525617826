import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet, axiosFilePost, FileEntry } from "../axiosHelper";

export function* fileUploadRequest({ payload, newFile }) {
  console.log("running");
  try {
    const { data } = yield FileEntry(newFile, `v1/files/request-upload`);
    yield put(actions.fileUploadEntrySuccess(payload, data?._id));
  } catch (error) {
    yield put(actions.fileUploadEntryFailure());
  }
}

export function* fileUploadEntrySuccess({ payload, id }) {
  try {
    const res = yield axiosFilePost(payload, `v1/files/upload/${id}`);
    yield put(actions.fileUploadSuccess(res.data));
  } catch (error) {
    yield put(actions.fileUploadFailure());
  }
}

export function* getFile({ fileId }) {
  try {
    const { data } = yield axiosGet(`v1/files/${fileId}`);
    console.log(data);
    yield put(actions.getFileSuccess(data));
  } catch (error) {
    yield put(actions.getFileFailure());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.REQUEST_FILE_UPLOAD, fileUploadRequest),
    takeLatest(actions.GET_FILE, getFile),
    takeLatest(actions.FILE_UPLOAD_ENTRY_SUCCESS, fileUploadEntrySuccess),
  ]);
}
