import React, { useState, useEffect } from 'react'
import { Plus } from '@phosphor-icons/react'
import TicketComp from './TicketComp'
import AdmissionTicket from '../Modals/AdmissionTickets/AdmissionTicket'
import { creationEventStore } from 'state/eventCreationStore'
import {
  AdditionalTicketResp,
  DiscountResp,
  DropinTicketResp,
  TicketsResp,
} from 'types/eventStep4'
import { infoMessage } from 'api/global'
import DropInTickets from '../Modals/DropinTicket/DropInTickets'
import DiscountCode from '../Modals/Discount/DiscountCode'
import { useGetDiscountCode } from 'api/EventCreation/CreationStep4'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

interface TicketRowProps {
  type: 'admission' | 'additional' | 'dropin' | 'discount'
}
interface EventData {
  _id: string
  admissionTickets: TicketsResp[]
  additionalOptions: AdditionalTicketResp[]
  dropInTicket: DropinTicketResp
}

const TicketRow: React.FC<TicketRowProps> = ({ type }) => {
  const { _id, admissionTickets, additionalOptions, dropInTicket } =
    creationEventStore(store => store.eventData) as EventData
  const [admissionModalOpen, setAdmissionModalOpen] = useState<boolean>(false)
  const [dropinModalOpen, setDropInModalopen] = useState<boolean>(false)
  const [discountModalOpen, setDiscountModalopen] = useState<boolean>(false)
  const {
    data: discountCodesData,
    isLoading: discountCodeLoading,
    isFetching: discountCodeFetching,
    refetch: discountCodeRefetch,
  } = useGetDiscountCode(_id)

  // const onDragEnd = (result: any) => {
  //   if (!result.destination) {
  //     return
  //   }

  //   const items = Array.from(admissionTickets)
  //   const [reorderedItem] = items.splice(result.source.index, 1)
  //   items.splice(result.destination.index, 0, reorderedItem)

  //   const updatedItems = items.map((ticket, index) => ({
  //     ...ticket,
  //     order: index + 1,
  //   }))

  //   // Update the state with the new order
  //   creationEventStore.setState(state => ({
  //     ...state,
  //     eventData: {
  //       ...state.eventData,
  //       admissionTickets: updatedItems,
  //     },
  //   }))
  // }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const items = Array.from(
      type === 'admission' ? admissionTickets : additionalOptions,
    )
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    const updatedItems = items.map((ticket, index) => ({
      ...ticket,
      order: index + 1,
    }))

    // Update the state with the new order
    creationEventStore.setState(state => ({
      ...state,
      eventData: {
        ...state.eventData,
        [type === 'admission' ? 'admissionTickets' : 'additionalOptions']:
          updatedItems,
      },
    }))
  }

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: 8,
    margin: '0 8px 0 0',
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightgrey' : 'transparent',
    display: 'flex',
    padding: 8,
    overflow: 'auto',
  })

  const handleAddClick = (
    type: 'admission' | 'additional' | 'dropin' | 'discount',
  ) => {
    switch (type) {
      case 'admission':
        setAdmissionModalOpen(true)
        break
      case 'additional':
        if (admissionTickets.length === 0) {
          return infoMessage('First create an admission ticket')
        }
        setAdmissionModalOpen(true)
        break
      case 'dropin':
        setDropInModalopen(true)
        break
      case 'discount':
        if (admissionTickets.length === 0) {
          return infoMessage('First create an admission ticket')
        }
        setDiscountModalopen(true)
        break
    }
  }

  useEffect(() => {
    discountCodeRefetch()
  }, [_id])

  return (
    <>
      <div className="mt-20px">
        <div className="flex align-center justify-between ">
          <div>
            <p className="text-16 w-600">
              {type === 'admission'
                ? 'Admission Ticket'
                : type === 'additional'
                ? 'Additional Ticket'
                : type === 'dropin'
                ? 'Dropin Ticket'
                : type === 'discount'
                ? 'Discount codes'
                : null}
            </p>
          </div>
          <div
            className="w-36px h-36px radius-rounded bg-blue flex align-center justify-center pointer"
            onClick={() => handleAddClick(type)}
          >
            <Plus size={12} color="#fff" />
          </div>
        </div>

        {type === 'admission' ? (
          // <div className="grid-50">
          //   {admissionTickets.length > 0 &&
          //     admissionTickets.map((ticket: TicketsResp) => {
          //       return (
          //         <div key={ticket._id}>
          //           <TicketComp
          //             visible={ticket.status === 'active' ? true : false}
          //             details={ticket}
          //             ticketType="admission"
          //           />
          //         </div>
          //       )
          //     })}
          // </div>

          // <DragDropContext onDragEnd={onDragEnd}>
          //   <Droppable droppableId="admissionTickets">
          //     {provided => (
          //       <div
          //         {...provided.droppableProps}
          //         ref={provided.innerRef}
          //         className="grid-50"
          //       >
          //         {admissionTickets.length > 0 &&
          //           admissionTickets.map(
          //             (ticket: TicketsResp, index: number) => (
          //               <Draggable
          //                 key={ticket._id}
          //                 draggableId={ticket._id}
          //                 index={index}
          //               >
          //                 {provided => (
          //                   <div
          //                     ref={provided.innerRef}
          //                     {...provided.draggableProps}
          //                     {...provided.dragHandleProps}
          //                   >
          //                     <TicketComp
          //                       visible={
          //                         ticket.status === 'active' ? true : false
          //                       }
          //                       details={ticket}
          //                       ticketType="admission"
          //                     />
          //                   </div>
          //                 )}
          //               </Draggable>
          //             ),
          //           )}
          //         {provided.placeholder}
          //       </div>
          //     )}
          //   </Droppable>
          // </DragDropContext>

          // new code for the tickets

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="admissionTickets" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="scroller"
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {admissionTickets.length > 0 &&
                    admissionTickets.map(
                      (ticket: TicketsResp, index: number) => (
                        <Draggable
                          key={ticket._id}
                          draggableId={ticket._id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <TicketComp
                                visible={
                                  ticket.status === 'active' ? true : false
                                }
                                details={ticket}
                                ticketType="admission"
                              />
                            </div>
                          )}
                        </Draggable>
                      ),
                    )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : type === 'additional' ? (
          // <div className="grid-50">
          //   {additionalOptions.length > 0 &&
          //     additionalOptions.map((ticket: AdditionalTicketResp) => {
          //       return (
          //         <div key={ticket._id}>
          //           <TicketComp
          //             visible={ticket.status === 'active' ? true : false}
          //             details={ticket}
          //             ticketType="additional"
          //           />
          //         </div>
          //       )
          //     })}
          // </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="admissionTickets">
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // className="grid-50"
                >
                  {additionalOptions.length > 0 &&
                    additionalOptions.map(
                      (ticket: TicketsResp, index: number) => (
                        <Draggable
                          key={ticket._id}
                          draggableId={ticket._id}
                          index={index}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TicketComp
                                visible={
                                  ticket.status === 'active' ? true : false
                                }
                                details={ticket}
                                ticketType="additional"
                              />
                            </div>
                          )}
                        </Draggable>
                      ),
                    )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : type === 'dropin' ? (
          <div className="grid-50">
            {dropInTicket && (
              <TicketComp
                visible={dropInTicket.status === 'active'}
                details={dropInTicket}
                ticketType="dropin"
              />
            )}
          </div>
        ) : type === 'discount' ? (
          <div>
            {discountCodeLoading || discountCodeFetching ? (
              <div className="w-full py-30px flex justify-center align-center">
                <ButtonSpinner size={30} color="#2ba7ca" />
              </div>
            ) : (
              <div>
                {discountCodesData.length > 0 &&
                  discountCodesData.map((discount: DiscountResp) => {
                    return (
                      <div key={discount._id}>
                        <h1>{discount.code}</h1>
                        <div className="grid-50">
                          {discount.admissionTickets.map(
                            (ticket: TicketsResp) => {
                              return (
                                <div key={ticket._id}>
                                  <TicketComp
                                    visible={
                                      discount.status === 'active'
                                        ? true
                                        : false
                                    }
                                    details={ticket}
                                    ticketType="discount"
                                  />
                                </div>
                              )
                            },
                          )}
                          {discount.additionalOptions.map(
                            (ticket: TicketsResp) => {
                              return (
                                <div key={ticket._id}>
                                  <TicketComp
                                    visible={
                                      discount.status === 'active'
                                        ? true
                                        : false
                                    }
                                    details={ticket}
                                    ticketType="discount"
                                  />
                                </div>
                              )
                            },
                          )}
                        </div>
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
        ) : null}
      </div>

      {admissionModalOpen && (
        <AdmissionTicket
          modalOpen={admissionModalOpen}
          modalClose={setAdmissionModalOpen}
          type={type}
        />
      )}

      {dropinModalOpen && (
        <DropInTickets
          modalOpen={dropinModalOpen}
          modalClose={setDropInModalopen}
        />
      )}

      {discountModalOpen && (
        <DiscountCode
          modalOpen={discountModalOpen}
          modalClose={setDiscountModalopen}
        />
      )}
    </>
  )
}

export default TicketRow
