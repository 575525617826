import React, { useState } from 'react'
import { Modal, Button, Upload, Checkbox, Input, Card } from 'antd'
import { Layout } from 'antd'
import Calendar from '../../../assets/Images/Bcalendar.svg'
import Watch from '../../../assets/Images/watch.svg'
import Respect from '../../../assets/Images/respect.svg'
import PuzzleAvatar from '../../../assets/Images/puzzleAvatar.svg'
import Plus from '../../../assets/Images/plusBlue.svg'
import Books from '../../../assets/Images/books.svg'
import SimpleCal from '../../../assets/Images/sCalendar.svg'
import SimpleWatch from '../../../assets/Images/sWatch.svg'
import simpleRespect from '../../../assets/Images/sRespect.svg'
import TextArea from 'antd/lib/input/TextArea'
import EventSaveandDraft from './EventSaveandDraft'
import { useHistory } from 'react-router-dom'
const Exitwithoutfinishing = ({
  Visible,
  isModalVisibleEvent,
  setModalVisibleEvent,
  props,
}: any) => {
  const [isModalVisibleSaveandDraft, setModalVisibleSaveandDraft] =
    useState(false)
  const history = useHistory()
  const handleOk = () => {
    setModalVisibleEvent(false)
  }

  return (
    <div>
      <Layout>
        <Modal
          visible={isModalVisibleEvent}
          onOk={handleOk}
          onCancel={() => {
            setModalVisibleEvent(false)
          }}
          centered={true}
          footer={null}
          className="map-modal temp-model-step2"
        >
          <div className="justify-center text-center">
            <h1 className="f-semibold text-color-black text-center">
              Exit without finishing
            </h1>
            <p className="text-center mt-20">
              {' '}
              If you leave now, your event won’t be created and your progress
              won’t be saved.
            </p>
            <div>
              <Button
                className="big-btn-gray mt-20"
                onClick={() => setModalVisibleSaveandDraft(true)}
              >
                Yes, exit
              </Button>
            </div>
            <div>
              <Button
                className="big-btn-blue mt-20"
                onClick={() => setModalVisibleSaveandDraft(false)}
              >
                Continue Editing
              </Button>
            </div>
          </div>
        </Modal>
      </Layout>
      {isModalVisibleSaveandDraft ? (
        <EventSaveandDraft
          setModalVisibleSaveandDraft={setModalVisibleSaveandDraft}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
export default Exitwithoutfinishing
