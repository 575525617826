import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Upload, Image, Spin } from 'antd'
import './styles/styles.scss'
import type { UploadProps } from 'antd/es/upload/interface'
import pdfIcon from 'assets/Images/pdf.svg'
import Coverimage from 'assets/Images/Coverimage.svg'
import Icon from 'assets/Images/icon.svg'
import { useDispatch, connect } from 'react-redux'
import fileActions from 'Redux/FileUpload/action'
import { File, Photo } from 'types/globalTypes'
interface PropsTypes {
  documentIds: string[]
  setDocumentIds: (value: string[]) => void
  fileData: File
  loading: boolean
  multiple?: boolean
  docType?: 'image/*' | '.pdf,.doc,.docx,.txt'
  files?: File[] | Photo[]
}

const DocUpload: React.FC<PropsTypes> = ({
  documentIds,
  setDocumentIds,
  fileData,
  loading,
  multiple,
  docType,
  files,
}) => {
  const dispatch = useDispatch()
  const [galleryUrl, setGalleryUrl] = useState<any>([])
  const [docs, setDocs] = useState([])
  const propsForPhoto: UploadProps = {
    showUploadList: false,
    className: 'mt-100',
    onChange: (info: any) => {
      if (info.file.status === 'uploading') {
        info.file.status = 'done'
      }
      if (info.file.status === 'done') {
        const fileObj = new FormData()
        fileObj.append('file', info.file.originFileObj)
        let newFile = {
          title: 'session',
          description: 'image_id',
          altText: 'img',
          sourceType: 'user_profile',
        }

        dispatch({
          type: fileActions.REQUEST_FILE_UPLOAD,
          payload: fileObj,
          newFile: newFile,
        })
      }
    },
    beforeUpload: file => {
      var url = URL.createObjectURL(file)
      let type = file.type.split(/[/]/)
      if (type[0] === 'image')
        setGalleryUrl((prevValue: any) => [
          ...prevValue,
          { url: url, type: type[0] },
        ])
      else if (type[0] === 'application') {
        setDocs((prevValue: any) => [...prevValue, { name: file.name }])
      }
      return true
    },
  }

  const handleDeleteImage = (idx: number) => {
    const newImageData = galleryUrl.filter(
      (_: any, index: number) => index !== idx,
    )
    const newPhotoIds = documentIds.filter(
      (_: any, index: number) => index !== idx,
    )
    setGalleryUrl(newImageData)
    setDocumentIds(newPhotoIds)
  }

  const handleDeleteFile = (idx: number) => {
    const newFileData = docs.filter((_: any, index: number) => index !== idx)
    const newFileIds = documentIds.filter(
      (_: any, index: number) => index !== idx,
    )
    setDocs(newFileData)
    setDocumentIds(newFileIds)
  }

  useEffect(() => {
    if (fileData?._id) {
      setDocumentIds([...documentIds, fileData._id])
      dispatch({
        type: fileActions.CLEAR_FILE_DATA,
      })
    }
  }, [fileData])

  useEffect(() => {
    const fileTypes = ['.pdf', '.doc', '.docx', '.txt']
    if (files?.length > 0) {
      files.map((d: File | Photo) => {
        if (fileTypes.includes(d.ext)) {
          setDocs(prev => [...prev, { name: d.name }])
        } else {
          setGalleryUrl((prev: any) => [
            ...prev,
            { url: (d as Photo).post, type: (d as Photo).ext },
          ])
        }
      })
    }
  }, [files])

  const uploadButton = (
    <div>
      <>
        <div className="upload-acover-pic">
          <img src={Coverimage} alt="avatar" />
          {docType === '.pdf,.doc,.docx,.txt' ? 'Upload docs' : 'Upload image'}
        </div>
      </>
    </div>
  )

  return (
    <Row gutter={10}>
      <Col>
        <Form.Item name="galleryIds">
          <Row>
            <Col className="d-flex cvcv">
              <Upload
                {...propsForPhoto}
                listType="picture-card"
                accept={docType || 'image/*,.pdf,.doc,.docx,.txt'}
                multiple={multiple || false}
                disabled={
                  !multiple && (galleryUrl.length > 0 || docs.length > 0)
                }
                className="cover-img-step-5"
              >
                {uploadButton}
              </Upload>
            </Col>
          </Row>
        </Form.Item>
      </Col>

      <Col>
        {galleryUrl.length > 0 && (
          <Col className="antd-img-upload flex scroller gap-20 pb-10px">
            {galleryUrl.map((item: any, index: number) => {
              return (
                <div className="img-preview" key={index}>
                  <img
                    alt="#img"
                    className="closing-icon"
                    src={Icon}
                    onClick={() => handleDeleteImage(index)}
                  />
                  {/* <img
                    key={index}
                    className="br-12 "
                    height={100}
                    width={100}
                    src={item.url}
                  /> */}
                  <Image
                    key={index}
                    className="br-12"
                    height={100}
                    width={100}
                    src={item.url}
                    rootClassName={'mb-10'}
                  />
                </div>
              )
            })}
          </Col>
        )}
      </Col>

      <Col>
        {docs.length > 0 && (
          <Col className="antd-img-upload flex scroller gap-20">
            {docs.map((item: any, index: number) => {
              return (
                <div className="img-preview" key={index}>
                  <img
                    alt="#img"
                    className="closing-icon"
                    src={Icon}
                    onClick={() => handleDeleteFile(index)}
                  />
                  <div>
                    <img src={pdfIcon} alt="icon" width={50} height={50} />
                    <p>{item.name}</p>
                  </div>
                </div>
              )
            })}
          </Col>
        )}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state: any) => ({
  eventsDataValue: state.events.eventsDataValue,
  eventOrganizationData: state.events.eventOrganizationData,
  fileData: state.fileUpload.fileData,
  loading: state.fileUpload.loading,
})
export default connect(mapStateToProps)(DocUpload)
