import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import plusBlue from 'assets/Images/plusBlue.svg'
import ConfirmationModal from './ConfirmationModal'
import { useGetEmailTemplates } from 'api/EmailTemplate'
import { creationEventStore } from 'state/eventCreationStore'
import Spinner from 'components/Spinner'
import EmptyData from 'components/global/EmptyData'
import { Envelope, PencilLine, Trash } from '@phosphor-icons/react'
import { AddTemplateToEventPayload } from 'types/eventStep5'
import { useAddEmailtemplateToEvent } from 'api/EventCreation/creationStep5'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface AddTemplateToEventProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AddTemplateToEvent: React.FC<AddTemplateToEventProps> = ({
  openModal,
  closeModal,
}) => {
  const { organizationId, _id } = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false)
  const [loaderId, setLoaderId] = useState<string | null>(null)
  const {
    data: orgEmailTemplates,
    isLoading: orgEmailLoading,
    isFetching: orgEmailFetching,
    refetch: orgEmailRefetch,
  } = useGetEmailTemplates(organizationId)
  const handleCancel = () => {
    closeModal(false)
  }
  const { mutate: addTemplate } = useAddEmailtemplateToEvent()

  const handleAddToEvent = (templateId: string) => {
    const payload: AddTemplateToEventPayload = {
      fromTemplate: true,
      emailTemplateIds: [templateId],
    }
    setLoaderId(templateId)
    addTemplate(
      { payload, eventId: _id },
      {
        onSuccess: data => {
          setLoaderId(null)
          setEventData(data)
          handleCancel()
        },
        onError: () => {
          setLoaderId(null)
        },
      },
    )
  }

  useEffect(() => {
    orgEmailRefetch()
  }, [organizationId])

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="confirmation-email"
        footer={false}
        width={700}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Email communication</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px h-25rem overflow-auto-y scroller">
          {orgEmailLoading || orgEmailFetching ? (
            <div className="py-30px flex justify-center">
              <Spinner />
            </div>
          ) : orgEmailTemplates.length > 0 ? (
            <div>
              {orgEmailTemplates.map(template => {
                return (
                  <div
                    key={template._id}
                    className="flex align-center justify-between gap-20px"
                  >
                    <div className="flex align-center gap-16px mt-10px">
                      <Envelope size={30} color="#2ba7ca" />
                      <div>
                        <h3 className="text-14 w-600">
                          {template.templateName}
                        </h3>
                        <p className="text-14 w-400">{template.description}</p>
                      </div>
                    </div>
                    <div className="flex align-center gap-16px">
                      <PencilLine size={20} color="#000" />
                      <Trash size={20} color="#ff6961" />
                      <button
                        className="borderd-btn"
                        onClick={() => handleAddToEvent(template._id)}
                      >
                        {loaderId === template._id ? (
                          <ButtonSpinner size={20} color="#2ba7ca" />
                        ) : (
                          'Add to event'
                        )}
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="h-full flex align-center justify-center">
              <EmptyData message="No templates available" />
            </div>
          )}
        </div>
        <div className="mt-30px flex gap-20px justify-between align-center">
          <button className=" btn-secondary">cancel</button>
          <button
            className="pointer flex align-center gap-16px simple-btn"
            onClick={() => setOpenTemplateModal(true)}
          >
            <img src={plusBlue}></img> <p>Add New template</p>
          </button>
        </div>
      </Modal>

      {openTemplateModal && (
        <ConfirmationModal
          openModal={openTemplateModal}
          closeModal={setOpenTemplateModal}
        />
      )}
    </div>
  )
}

export default AddTemplateToEvent
